import { Box, Button, Grid, Typography } from "@mui/material";
import { useState } from "react";
import WillowStaffSideNavbar from "../navigation/WillowStaffSideNavbar";
import WillowStaffTopNavbar from "../navigation/WillowStaffTopNavbar";
import UploadSingleProgramField from "../managePrograms/UploadSingleProgramField";
import UploadSingleProviderField from "../managePrograms/UploadSingleProviderField";
import WillowStaffROIStatistics from "../managePrograms/WillowStaffROIStatistics";
import UploadProfiles from "../uploadProfiles/UploadProfiles";
import JobUpload from "../uploadJobs/JobUpload";
import WageDataUpload from "../uploadJobs/WageDataUpload";
import EducationAttainmentUpload from "../uploadJobs/EducationAttainmentUpload";
import TestScorecard from "../testScorecard/TestScorecard";
import UploadJobProspects from "../uploadJobProspects/UploadJobProspects";
import UploadCompetition from "../uploadJobProspects/UploadJobCompetition";
import JobSalaryUpdate from "../uploadJobProspects/JobSalaryUpdate";
import RemoveKey from "../managePrograms/RemoveKey";
import CalculateTotalProgramCosts from "../managePrograms/CalculateTotalProgramCost";
import DuplicateProviders from "./DuplicateProviders";
import CountDuplicateVideos from "../duplicateVideos/CountDuplicateVideos";
import HandleOldToNewTransfer from "../transferOldToNew/TransferOldToNew";
import AddMSACodes from "../addMSACodes/AddMSACodes";
import AddWageData from "../addWageData/AddWageData";
import CityProgramCount from "../cityProgramCount/CityProgramCount";
import AddIndustryToVideos from "../addIndustryToVideos/AddIndustryToVideos";
import useHandleError from "../../../shared/hooks/errorHandling/useHandleError";
import { LogEventType } from "../../../shared/types/logEnums";

const WillowStaffDashboardContainer = () => {
  const [open, setOpen] = useState(false);
  const { handleError } = useHandleError();

  // const { setIsOpen } = useTour(); // Use the hook for tour control
  // const loggedInStudent = useRecoilValue(loggedInStudentAtom);
  // useEffect(() => {
  //   if (!loggedInStudent) return;
  //   if (loggedInStudent.completedTours.includes(TourName.PROFILE)) return;
  //   setIsOpen(true);
  // }, [loggedInStudent, setIsOpen]);

  const testButton = () => {
    console.log("Test button clicked");
    handleError({
      error: "This is an error",
      snackbarMessage: `There was an error updating please refresh and try again.`,
      eventType: LogEventType.PROFILE_ACADEMICS_ERROR,
      file: "useEditAcademicsDialog.ts",
    });
  };

  return (
    <Box sx={{ display: "flex", backgroundColor: "#fafafa" }}>
      <Box component="main" sx={{ flexGrow: 1, p: 2 }}>
        <Box>
          <Grid container spacing={2}>
            {/* <Grid item xs={12} sm={6}>
              <UploadProviders />
            </Grid>
            <Grid item xs={12} sm={6}>
              <UploadPrograms />
            </Grid> */}
            <Grid item xs={12} sm={6}>
              <UploadSingleProgramField />
            </Grid>
            <Grid item xs={12} sm={6}>
              <UploadSingleProviderField />
            </Grid>
            {/* <Grid item xs={12} sm={4}>
              <TransformProgramColumnToAnother />
            </Grid>
            */}
            <Grid item xs={12}>
              <WillowStaffROIStatistics />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" sx={{ mt: 2 }}>
                Upload Profiles
              </Typography>
              <UploadProfiles />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" sx={{ mt: 2 }}>
                Upload Jobs
              </Typography>
              <JobUpload />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" sx={{ mt: 2 }}>
                Upload Wage Data
              </Typography>
              <WageDataUpload />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" sx={{ mt: 2 }}>
                Add MSA Codes
              </Typography>
              <AddMSACodes />
            </Grid>
            {/* <Grid item xs={12}>
              <CityProgramCount />
            </Grid> */}
            <Grid item xs={12}>
              <AddIndustryToVideos />
            </Grid>
          </Grid>
        </Box>
        {/* <Box>
          <DuplicateProviders />
        </Box>
        <Box>
          <CountDuplicateVideos />
        </Box> */}
        <Box sx={{ p: 2 }}>
          <Button onClick={() => testButton()}>Test Button</Button>
        </Box>
      </Box>
    </Box>
  );
};

export default WillowStaffDashboardContainer;
